import { 
    Snackbar, 
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'

function SnackbarComponent({snackBarMessage, snackBarOpen, setSnackBarOpen}){
    return (
        <Snackbar 
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            open={snackBarOpen}
            message={snackBarMessage}
            onClose={()=>setSnackBarOpen(false)}
            autoHideDuration={3000}
            action={
                <IconButton 
                    size='small' 
                    aria-label='close' 
                    color='inherit' 
                    onClick={()=>setSnackBarOpen(false)}
                >
                    <CloseIcon fontSize='small' />
                </IconButton>
            }
        />
    )
}

export default SnackbarComponent;