import {
    CircularProgress,
    Backdrop
} from '@mui/material';
function Loader({loading}){
    return (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    )
}

export default Loader;