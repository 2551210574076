import { Fragment } from 'react';
import { 
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Backdrop,
    TextField
} from '@mui/material';

import isEmpty from 'lodash/isEmpty'

import Loader from "./Loader";

export default function({open, onSubmit, onClose, fields, loading}) {
    const content = fields.map((obj, idx)=>{
        const {label, value, onChange, type, size} = obj;
        if(isEmpty(obj))
            return (<Fragment key={idx}><br/><br/></Fragment>);
        return (
            <TextField
                key={idx}
                InputLabelProps={{shrink: true}}
                variant="outlined"
                margin="dense"
                size="small"
                color="primary"
                type={type || 'string'}
                label={label}
                value={value == null ? '' : value}
                sx={{ width: size }}
                onChange={onChange}
            />
        );
    });
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Edit Details</DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <Button onClick={onSubmit}>Submit</Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
            <Loader loading={loading}/>
        </Dialog>
    )
} 