import { useEffect, useState } from 'react';
import axios from 'axios';
import EditDialogBox from '../components/EditDialogBox';
import Snackbar from '../components/Snackbar';

export default function({activeId, onClose, activityType, selectedIds}) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState();
    const [ownerId, setOwnerId] = useState(); 
    const [ownerName, setOwnerName] = useState(); 
    const [ownerPhone, setOwnerPhone] = useState();
    const [ownerAddress, setOwnerAddress] = useState();  
    const [ownerCity, setOwnerCity] = useState(); 
    const [ownerState, setOwnerState] = useState();     
    const [ownerZip, setOwnerZip] = useState();
    const [ownerComments, setOwnerComments] = useState(); 

    const fetchURL  = `/api/owners/${activeId}`
    const fetch = () => {
        setLoading(true);
        axios.get(fetchURL).then(res => {
            const { data: { data } } = res;
            setOwnerId(data.ownerid);
            setOwnerName(data.name);
            setOwnerPhone(data.phonenumber);
            setOwnerAddress(data.address);
            setOwnerCity(data.city);
            setOwnerState(data.state);
            setOwnerZip(data.zip);
            setOwnerComments(data.ownercomments);

            setLoading(false);
        });
    }
    const onSubmit = (submitURL) => {
        setLoading(true);
        axios.post(submitURL, {
            ownerId,
            ownerName,
            ownerPhone,
            ownerAddress,
            ownerCity,
            ownerState,
            ownerZip,
            ownerComments,
            selectedIds,
        }).then(res => {
            setLoading(false);
            setSnackBarMessage(res.data.result);
            setSnackBarOpen(true);
            onClose();
        });
    };
    useEffect(()=>{
        if(activeId == null) {
            setOpen(false);
        }
        else {
            setOpen(true);
            fetch();
        }
    },[activeId]);

    const fields = [
        {label:'Owner Name', value:ownerName, onChange: (e)=>setOwnerName(e.target.value), size: '400px'},
        {label:'Owner Phone', value:ownerPhone, onChange: (e)=>setOwnerPhone(e.target.value), size: '400px'},
        {label:'Owner Address', value:ownerAddress, onChange: (e)=>setOwnerAddress(e.target.value), size: '820px'},
        {label:'Owner City', value:ownerCity, onChange: (e)=>setOwnerCity(e.target.value), size: '280px'},
        {label:'Owner State', value:ownerState, onChange: (e)=>setOwnerState(e.target.value), size: '200px'},
        {label:'Owner Zip', value:ownerZip, onChange: (e)=>setOwnerZip(e.target.value), size: '200px'},
        {label:'Owner Comments', value:ownerComments, onChange: (e)=>setOwnerComments(e.target.value), size: '820px'},
    ];

    return (
        <>
            <EditDialogBox 
                open={open} 
                onClose={onClose} 
                loading={loading} 
                fields={fields}
                onSubmit={()=>{onSubmit(`/api/owners/${activityType == 'merge' ? 'merge' : activeId}`)}}
            />
            <Snackbar snackBarOpen={snackBarOpen} snackBarMessage={snackBarMessage} setSnackBarOpen={setSnackBarOpen}/>
        </>
    );
}