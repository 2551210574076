import { useState } from "react";
import isEmpty from 'lodash/isEmpty'

import DataGrid from "../components/DataGrid";
import UnitEdit from "./UnitEdit";

import currencyFormatter from "../utils/currencyFormatter";

const columns = [
    // {
    //     field: 'UnitID',
    //     headerName: 'UnitID',
    //     type: 'number',
    //     valueGetter: ({row}) => row.id,
    //     filterable: false,
    //     width: 100,
    // },
    {
        field: 'UnitName',
        headerName: 'UnitName',
        type: 'string',
        valueGetter: ({row}) => row.unitname,
        width: 500,
    },
    {
        field: 'County',
        headerName: 'County',
        type: 'string',
        valueGetter: ({row}) => row.county,
        width: 300,
    },
    {
        field: 'AreaAcres',
        headerName: 'Gross',
        type: 'number',
        valueGetter: ({row}) => row.areaacres,
        width: 100,
    },
    {
        field: 'DNRA',
        headerName: '$/NRA',
        type: 'number',
        valueGetter: ({row}) => row.dnra * 1,
        valueFormatter: currencyFormatter,
        width: 300,
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        type: 'string',
        valueGetter: ({row}) => row.comments,
        filterable: false,
        flex: 1,
    },
  ];

function Units() {
    const [activeId, setActiveId] = useState();
    const [activityType, setActivityType] = useState('none');
    const [selectedIds, setSelectedIds] = useState([]);
    return (
        <>
            <DataGrid 
                columns={columns} 
                baseURL='/api/units?wksp=false' 
                searchCriteria={null} 
                searchKey={null}
                activeId={activeId}
                onRowDoubleClick={({row})=>{
                    setActivityType('edit');
                    setActiveId(row.id);
                    setSelectedIds([]);
                }}                
                onMerge={(rowSelectionModel)=>{
                    if(isEmpty(rowSelectionModel))
                        return;
                    setActivityType('merge');
                    setActiveId(rowSelectionModel[0]);
                    setSelectedIds(rowSelectionModel);
                }} 
            />
            <UnitEdit  
                activeId={activeId} 
                activityType={activityType}
                selectedIds={selectedIds}
                onClose={()=>{
                    setActivityType(null);
                    setActiveId(null);
                    setSelectedIds([]);
                }}
                />
        </>
    );
}

export default Units;