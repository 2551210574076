import { useState } from "react";
import axios from 'axios';

import DataGrid from "../components/DataGrid";
import Snackbar from "../components/Snackbar";
import Loader   from "../components/Loader";

import AppraisalEdit from "./AppraisalEdit";

const columns = [
    {
        field: 'Name',
        headerName: 'Owner',
        type: 'string',
        valueGetter: ({row}) => row.normownername,
        width: 300,
    },
    {
        field: 'OwnerInterestType',
        headerName: 'Int',
        type: 'string',
        valueGetter: ({row}) => row.ownerinteresttype,
        filterable: false,
        width: 60,
    },
    {
        field: 'OwnerDecimalInterest',
        headerName: 'Decimal',
        type: 'number',
        valueGetter: ({row}) => row.ownerdecimalinterest,
        filterable: false,
        width: 100,
    },
    {
        field: 'AreaAcres',
        headerName: 'Gross',
        type: 'number',
        valueGetter: ({row}) => row.areaacres,
        filterable: false,
        width: 100,
    },
    {
        field: 'NRA',
        headerName: 'NRA',
        type: 'number',
        valueGetter: ({row}) => row.areaacres * row.ownerdecimalinterest * 8,
        filterable: false,
        width: 100,
    },
    {
        field: 'OperatorCompanyName',
        headerName: 'Operator',
        type: 'string',
        valueGetter: ({row}) => row.operatorcompanyname,
        filterable: false,
        width: 150,
    },
    {
        field: 'UnitName',
        headerName: 'Unit',
        type: 'string',
        valueGetter: ({row}) => row.unitname,
        width: 220,
    },
    {
        field: 'County',
        headerName: 'County',
        type: 'string',
        valueGetter: ({row}) => row.county,
        width: 150,
    },
    {
        field: 'UPN',
        headerName: '$/NRA',
        type: 'string',
        valueGetter: ({row}) => row.upn,
        width: 100,
    },
    {
        field: 'UnitPrice',
        headerName: 'Unit Price',
        type: 'string',
        valueGetter: ({row}) => row.unitprice,
        width: 100,
    },    
    {
        field: 'LeaseDescription',
        headerName: 'Lease Description',
        valueGetter: ({row}) => row.leasedescription,
        type: 'string',
        filterable: false,
        flex: 1
    },
  ];

function Appraisals() {
    const [activeId, setActiveId] = useState();

    const [loading, setLoading] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState();

    const wspcaURL  = `/api/appraisals/workspace/remove`

    const onAddToCart = (selectedIds) => {
        setLoading(true);
        axios.post(wspcaURL, {
            selectedIds,
        }).then(res => {
            setLoading(false);
            setSnackBarMessage(res.data.result);
            setSnackBarOpen(true);
            setActiveId(null);
        });
    };

    return (
        <>
            <DataGrid 
                columns={columns} 
                baseURL='/api/appraisals?wksp=true' 
                searchCriteria={null} 
                searchKey={null}
                activeId={activeId}
                onRowDoubleClick={({row})=>setActiveId(row.id)}
                onRemoveFromCart={(rowSelectionModel)=>onAddToCart(rowSelectionModel)} 
            />
            <AppraisalEdit activeId={activeId} onClose={()=>setActiveId(null)}/>
            <Loader loading={loading}/>
            <Snackbar snackBarOpen={snackBarOpen} snackBarMessage={snackBarMessage} setSnackBarOpen={setSnackBarOpen}/>
        </>
    );
}

export default Appraisals;