import { useEffect, useState } from 'react';
import axios from 'axios';
import EditDialogBox from '../components/EditDialogBox';
import Snackbar from '../components/Snackbar';

export default function({activeId, onClose, activityType, selectedIds}) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState();
    const [unitId, setUnitId] = useState(); 
    const [unitName, setUnitName] = useState(); 
    const [unitCounty, setUnitCounty] = useState(); 
    const [unitDNRA, setUnitDNRA] = useState(); 
    const [unitAreaAcres, setUnitAreaAcres] = useState(); 
    const [unitComments, setUnitComments] = useState(); 

    const fetchURL = `/api/units/${activeId}`
    const fetch = () => {
        setLoading(true);
        axios.get(fetchURL).then(res => {
            const { data: { data } } = res;
            setUnitId(data.unitid);
            setUnitName(data.unitname);
            setUnitCounty(data.unitcounty);
            setUnitDNRA(data.unitdnra * 1);
            setUnitAreaAcres(data.unitareaacres * 1);
            setUnitComments(data.unitcomments);

            setLoading(false);
        });
    }
    const onSubmit = (submitURL) => {
        setLoading(true);
        axios.post(submitURL, {
            unitId,
            unitName,
            unitCounty,
            unitDNRA,
            unitAreaAcres,
            unitComments,
            selectedIds
        }).then(res => {
            setLoading(false);
            setSnackBarMessage(res.data.result);
            setSnackBarOpen(true);
            onClose();
        });
    };
    useEffect(()=>{
        if(activeId == null) {
            setOpen(false);
        }
        else {
            setOpen(true);
            fetch();
        }
    },[activeId]);

    const fields = [
        {label:'Unit Name', value:unitName, onChange: (e)=>setUnitName(e.target.value), size: '400px'},
        {label:'Unit County', value:unitCounty, onChange: (e)=>setUnitCounty(e.target.value), size: '400px'},
        {label:'Unit DNRA', value:unitDNRA, onChange: (e)=>setUnitDNRA(e.target.value), type: 'number', size: '400px'},
        {label:'Unit Gross', value:unitAreaAcres, onChange: (e)=>setUnitAreaAcres(e.target.value), type: 'number', size: '400px'},
        {label:'Unit Comments', value:unitComments, onChange: (e)=>setUnitComments(e.target.value), size: '820px'}
    ];

    return (
        <>
            <EditDialogBox 
                open={open} 
                onClose={onClose} 
                loading={loading} 
                fields={fields}
                onSubmit={()=>{onSubmit(`/api/units/${activityType == 'merge' ? 'merge' : activeId}`)}}
            />
            <Snackbar snackBarOpen={snackBarOpen} snackBarMessage={snackBarMessage} setSnackBarOpen={setSnackBarOpen}/>
        </>
    );
}