import { useState, useEffect, useMemo } from 'react';
import { useAuth } from "react-auth-verification-context";
import { useNavigate } from "react-router-dom";
import { 
  DataGridPremium, 
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  getGridStringOperators,
  getGridNumericOperators,
} from '@mui/x-data-grid-premium';
import { Button } from '@mui/material';
import MergeIcon from '@mui/icons-material/Merge';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import axios from 'axios';
import debounce from "lodash/debounce";

import './DataGrid.css'

function GridToolbar({onMerge, onAddToCart, rowSelectionModel, onRemoveFromCart}) {
  const merge = onMerge ? true : false;
  const acart = onAddToCart ? true : false;
  const rcart = onRemoveFromCart ? true : false;
  return ()=>(
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarExport />
      {merge &&
      <Button 
        size='small'
        startIcon={<MergeIcon color='primary' fontSize='small'/>}
        onClick={()=>{onMerge(rowSelectionModel)}}
      >
        MERGE
      </Button>
      }
      {acart &&
      <Button 
        size='small'
        startIcon={<PlaylistAddIcon color='primary' fontSize='small'/>}        
        onClick={()=>{onAddToCart(rowSelectionModel)}}
      >
        WORKSPACE
      </Button>
      }
      {rcart &&
      <Button 
        size='small'
        startIcon={<PlaylistRemoveIcon color='primary' fontSize='small'/>}        
        onClick={()=>{onRemoveFromCart(rowSelectionModel)}}
      >
        WORKSPACE
      </Button>
      }
    </GridToolbarContainer>
  );
}
  
export default function({baseURL, columns, searchCriteria, searchKey, onRowDoubleClick, activeId, onMerge, onAddToCart, onRemoveFromCart}) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [sortKey, setSortKey] = useState('undefined');
  const [sortDir, setSortDir] = useState('asc');
  const [filters, setFilters] = useState('undefined');
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [rowSelectionModel, setRowSelectionModel] = useState();  

  const { logout } = useAuth();
  const navigate = useNavigate();
  
  const fetchURL =  `${baseURL}&offset=${page*perPage}&limit=${perPage}`+
                    `&searchKey=${searchKey}&searchCriteria=${searchCriteria}`+
                    `&sortKey=${sortKey}&sortDir=${sortDir}`+
                    `&filters=${encodeURIComponent(filters)}`;

  const newStringFilterOptions = getGridStringOperators().filter((op) => op.value === 'contains'); 
  const newNumberFilterOptions = getGridNumericOperators().filter((op) => op.value !== 'isAnyOf');

  const mappedColumns = useMemo(()=>(
    columns.map((col) => {
      if (col['type'] === 'string' && col['filterable'] !== false) 
        return { ...col, filterOperators: newStringFilterOptions };
      else if (col['type'] === 'number' && col['filterable'] !== false) 
        return { ...col, filterOperators: newNumberFilterOptions };
      else  
        return col;
    })
  ),[columns]);

  const fetch = () => {
    setLoading(true);
    setRowSelectionModel([]);
    axios.get(fetchURL).then(res => {        
      const { data: {total, data} } = res;
      if(res.data.result === 'ERROR' && res.data.data === 'UNAUTHORIZED') {
        logout();
        setLoading(false);
        navigate('/');
      }
      else {
        setTotalRows(total);
        setData(data);
        setLoading(false);
      }
    });
  };

  useEffect(()=>{
    fetch();
  },[perPage, page, sortKey, sortDir, searchKey, filters]);

  useEffect(()=>{
    if(activeId == null) {
      setRowSelectionModel([]);
      fetch();
    }
  },[activeId]);


  return (
    <div className='data-grid-container'>
      <DataGridPremium
        pagination
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        loading={loading}
        columns={mappedColumns}
        rows={data}
        rowCount={totalRows}
        pageSizeOptions={[20, 50, 100, 200, 500, 1000, 10000, 100000]}
        onPaginationModelChange={({pageSize, page})=>{
          setPerPage(pageSize);
          setPage(page);
        }}
        onSortModelChange={(sortModel)=>{
          if(sortModel.length == 0) {
            setSortKey('undefined');
          }
          else {
            setSortKey(sortModel[0]['field']);
            setSortDir(sortModel[0]['sort']);
          }
        }}
        onFilterModelChange={debounce((args)=>{
          args.items.length == 0 ? 
            setFilters('undefined') : 
            setFilters(JSON.stringify(args));
        },500)}   
        slots={{
          toolbar: 
            GridToolbar({onMerge,onAddToCart,onRemoveFromCart,rowSelectionModel})
        }}
        // slotProps={{
        //   filterPanel: {
        //     disableAddFilterButton: true,
        //     disableRemoveAllButton: true,
        //   },
        // }}
        density='compact'
        disableRowSelectionOnClick
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        initialState={{
          pagination: { paginationModel: { pageSize: 20, page: 0 } },
        }}
        onRowDoubleClick={onRowDoubleClick}
      />
    </div>
  );
}