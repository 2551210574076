// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-grid-container {
    height: calc(100vh - 105px);
    width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/components/DataGrid.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,YAAY;AAChB","sourcesContent":[".data-grid-container {\n    height: calc(100vh - 105px);\n    width: 100vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
