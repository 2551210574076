import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  NavLink,
  Outlet
} from "react-router-dom";

import { useAuth } from "react-auth-verification-context";
import axios from 'axios';

import Owners     from "./views/Owners";
import Units      from "./views/Units";
import Appraisals from "./views/Appraisals";
import Workspace from "./views/Workspace";
import Home       from "./views/Home";

import Loader from './components/Loader';

import './App.css';

function App() {
  const { isAuthenticated, login, logout, attributes } = useAuth();
  const [loading, setLoading] = useState(false);
  useEffect(()=>{
    setLoading(true);
    axios.get('/api/login').then(res => {
      if(res.data.result === 'SUCCESS') {
        login(res.data.data);
      }
      setLoading(false);
    });
  },[]);
  return (
    <BrowserRouter>
      <div className='mast'>
        <img className='logo' src='/logotc.png' height='40px' width='40px'/>
        <span className='title'>LeaseMax</span>
        {!loading  && isAuthenticated && 
          <NavLink to="/">
            <span className='username'><i>Logged in as: {attributes}</i></span>
          </NavLink>
        }
      </div>
      {isAuthenticated && <AppNavigation/>}
      <AppRoutes/>
      <Loader loading={loading}/>      
    </BrowserRouter>
  );
}

function AppNavigation() {
  return (
    <nav>
      <ul className='nav-ul'>
        <li className='nav-li'>
          <NavLink
            to="/leases"
            className={({ isActive }) => (isActive ? 'is-active' : 'inactive')}
          >
            Leases
          </NavLink>
        </li>
        <li className='nav-li'>
          <NavLink
            to="/units"
            className={({ isActive }) => (isActive ? 'is-active' : 'inactive')}
          >
            Units
          </NavLink>
        </li>
        <li className='nav-li'>
          <NavLink
            to="/owners"
            className={({ isActive }) => (isActive ? 'is-active' : 'inactive')}
          >
            Owners
          </NavLink>
        </li>
        <li className='nav-li'>
          <NavLink
            to="/workspace"
            className={({ isActive }) => (isActive ? 'is-active' : 'inactive')}
          >
            Workspace
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="/owners"     element={<Owners />} />
        <Route path="/units"      element={<Units />} />
        <Route path="/leases"     element={<Appraisals />} />
        <Route path="/workspace"  element={<Workspace />} />
        <Route path="/" element={<Home />} />
      </Routes>
      <Outlet />
    </>
  );
}

export default App;