import { useState } from "react";
import { useAuth } from "react-auth-verification-context";
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axios from 'axios';
import Loader from "../components/Loader";
import Snackbar from "../components/Snackbar";

function Home() {
    const { isAuthenticated, attributes } = useAuth();
    return(
        <div style={{ marginTop: '10%', textAlign: 'center' }}>
            {isAuthenticated && <h3>Welcome {attributes}!</h3>}
            {isAuthenticated && <SignOut/>} 
            {isAuthenticated || <SignIn/>}
        </div>
    )
}

function SignOut() {
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const endSession = () => {
    setLoading(true);
    axios.post('/api/logout', {}).then(res => {
      setLoading(false);
      if(res.data.result === 'SUCCESS') {
        logout();
      }
    });
  };
  return (
    <>
      <Button
        variant="contained"
        style={{width: 400}}
        sx={{ mt: 3, mb: 2 }}
        onClick={()=>endSession()}
      >
        Logout
      </Button>
      <Loader loading={loading}/>
    </>
  )
}

function SignIn() {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true);
    axios.post('/api/login', {
      username: data.get('username'),
      password: data.get('password'),
    }).then(res => {
      setLoading(false);
      setSnackBarMessage(res.data.result);
      setSnackBarOpen(true);
      if(res.data.result === 'SUCCESS') {
        login(res.data.data);
      }
    });
  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Loader loading={loading}/>
        <Snackbar snackBarOpen={snackBarOpen} snackBarMessage={snackBarMessage} setSnackBarOpen={setSnackBarOpen}/>
      </Container>
  );
}

export default Home;